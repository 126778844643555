import { AreaChartOutlined, HomeOutlined, SettingOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

/**
 * Components
 */
/* 首页 Home */
import PageDashboard from '@/pages/dashboard';

/* 内容审核 Content Moderation */
import PageContentModerationPosts from '@/pages/content-moderation/content-moderation/posts';
import PageContentModerationShorts from '@/pages/content-moderation/content-moderation/shorts';
import PageContentModerationMemecoin from '@/pages/content-moderation/content-moderation/memecoin';
import PageContentModerationLive from '@/pages/content-moderation/content-moderation/live';
import PageContentModerationModerationRecordsPosts from '@/pages/content-moderation/moderation-records/posts';
import PageContentModerationModerationRecordsShorts from '@/pages/content-moderation/moderation-records/shorts';
import PageContentModerationModerationRecordsMemecoin from '@/pages/content-moderation/moderation-records/memecoin';
import PageContentModerationModerationRecordsLive from '@/pages/content-moderation/moderation-records/live';
import PageAutomatedModerationPosts from '@/pages/content-moderation/automated-moderation/posts';
import PageAutomatedModerationShorts from '@/pages/content-moderation/automated-moderation/shorts';
import PageAutomatedModerationMemecoin from '@/pages/content-moderation/automated-moderation/memecoin';
import PageAutomatedModerationLive from '@/pages/content-moderation/automated-moderation/live';
import PageAutomatedConfig from '@/pages/content-moderation/strategy-config/automated-config';

/* 举报管理 Reports */
// 举报审核
import PageReportReviewPosts from '@/pages/report/report-moderation/posts';
import PageReportReviewReplies from '@/pages/report/report-moderation/posts-comments';
import PageReportReviewProfile from '@/pages/report/report-moderation/profile';
import PageReportModerationShorts from '@/pages/report/report-moderation/shorts';
import PageReportModerationShortsComments from '@/pages/report/report-moderation/shorts-comments';
// 举报审核记录
import PageReportReviewRecordsPosts from '@/pages/report/moderation-records/posts';
import PageReportReviewRecordsReplies from '@/pages/report/moderation-records/posts-comments';
import PageReportReviewRecordsProfile from '@/pages/report/moderation-records/profile';
import PageReportModerationRecordsShorts from '@/pages/report/moderation-records/shorts';
import PageReportReviewRecordsShortsComments from '@/pages/report/moderation-records/shorts-comments';

/* 系统管理 System Management */
// 账号管理
import PageAccountManagement from '@/pages/system-management/account-management';
// 角色管理
import PageRoleManagement from '@/pages/system-management/role-management';
// 系统日志
import PageLogs from '@/pages/system-management/logs';
// 导出管理
import PageExports from '@/pages/system-management/exports';

/**
 * Types
 */
import type { MenuDataItem } from '@ant-design/pro-components';

const routes = [
    /* 首页 Home */
    {
        element: <PageDashboard />,
        icon: <HomeOutlined />,
        name: (<FormattedMessage id="m.Home" />) as unknown as string,
        path: '/',
    },
    /* 内容审核 Content Moderation */
    {
        children: [
            {
                children: [
                    {
                        access: 'Content-Moderation_Content-Moderation_Post',
                        element: <PageContentModerationPosts />,
                        name: <FormattedMessage id="m.Content-Moderation_Content-Moderation_Post" />,
                        path: 'posts',
                    },
                    {
                        access: 'Content-Moderation_Content-Moderation_Shorts',
                        element: <PageContentModerationShorts />,
                        name: <FormattedMessage id="m.Content-Moderation_Content-Moderation_Shorts" />,
                        path: 'shorts',
                    },
                    {
                        access: 'Content-Moderation_Content-Moderation_Memecoin',
                        element: <PageContentModerationMemecoin />,
                        name: <FormattedMessage id="m.Content-Moderation_Content-Moderation_Memecoin" />,
                        path: 'memecoin',
                    },
                    {
                        access: 'Content-Moderation_Content-Moderation_Live',
                        element: <PageContentModerationLive />,
                        name: <FormattedMessage id="m.Content-Moderation_Content-Moderation_Live" />,
                        path: 'live',
                    },
                ],
                access: 'Content-Moderation_Content-Moderation',
                name: <FormattedMessage id="m.Content-Moderation_Content-Moderation" />,
                path: 'content-moderation',
            },
            {
                children: [
                    {
                        access: 'Content-Moderation_Moderation-Records_Post',
                        element: <PageContentModerationModerationRecordsPosts />,
                        name: <FormattedMessage id="m.Content-Moderation_Moderation-Records_Post" />,
                        path: 'posts',
                    },
                    {
                        access: 'Content-Moderation_Moderation-Records_Shorts',
                        element: <PageContentModerationModerationRecordsShorts />,
                        name: <FormattedMessage id="m.Content-Moderation_Moderation-Records_Shorts" />,
                        path: 'shorts',
                    },
                    {
                        access: 'Content-Moderation_Moderation-Records_Memecoin',
                        element: <PageContentModerationModerationRecordsMemecoin />,
                        name: <FormattedMessage id="m.Content-Moderation_Moderation-Records_Memecoin" />,
                        path: 'memecoin',
                    },
                    {
                        access: 'Content-Moderation_Moderation-Records_Live',
                        element: <PageContentModerationModerationRecordsLive />,
                        name: <FormattedMessage id="m.Content-Moderation_Moderation-Records_Live" />,
                        path: 'live',
                    },
                ],
                access: 'Content-Moderation_Moderation-Records',
                name: <FormattedMessage id="m.Content-Moderation_Moderation-Records" />,
                path: 'moderation-records',
            },
            {
                children: [
                    {
                        access: 'Content-Moderation_Automated-Moderation_Post',
                        element: <PageAutomatedModerationPosts />,
                        name: <FormattedMessage id="m.Content-Moderation_Automated-Moderation_Post" />,
                        path: 'posts',
                    },
                    {
                        access: 'Content-Moderation_Automated-Moderation_Shorts',
                        element: <PageAutomatedModerationShorts />,
                        name: <FormattedMessage id="m.Content-Moderation_Automated-Moderation_Shorts" />,
                        path: 'shorts',
                    },
                    {
                        access: 'Content-Moderation_Automated-Moderation_Memecoin',
                        element: <PageAutomatedModerationMemecoin />,
                        name: <FormattedMessage id="m.Content-Moderation_Automated-Moderation_Memecoin" />,
                        path: 'memecoins',
                    },
                    {
                        access: 'Content-Moderation_Automated-Moderation_Live',
                        element: <PageAutomatedModerationLive />,
                        name: <FormattedMessage id="m.Content-Moderation_Automated-Moderation_Live" />,
                        path: 'live',
                    },
                ],
                access: 'Content-Moderation_Automated-Moderation',
                name: <FormattedMessage id="m.Content-Moderation_Automated-Moderation" />,
                path: 'automated-moderation',
            },
            {
                children: [
                    {
                        access: 'Content-Moderation_Strategy-Config_Automated-Config',
                        element: <PageAutomatedConfig />,
                        name: <FormattedMessage id="m.Content-Moderation_Strategy-Config_Automated-Config" />,
                        path: 'automated-config',
                    },
                ],
                access: 'Content-Moderation_Strategy-Config',
                name: <FormattedMessage id="m.Content-Moderation_Strategy-Config" />,
                path: 'strategy-config',
            },
        ],
        icon: <AreaChartOutlined />,
        name: <FormattedMessage id="m.Content-Moderation" />,
        access: 'Content-Moderation',
        path: 'content-moderation',
    },
    /* 举报管理 Reports */
    {
        children: [
            {
                children: [
                    {
                        access: 'Reports_Report-Moderation_Posts',
                        element: <PageReportReviewPosts />,
                        name: <FormattedMessage id="m.rm.posts" />,
                        path: 'posts',
                    },
                    {
                        access: 'Reports_Report-Moderation_Posts-Comments',
                        element: <PageReportReviewReplies />,
                        name: <FormattedMessage id="m.rm.posts-comments" />,
                        path: 'posts-comments',
                    },
                    {
                        access: 'Reports_Report-Moderation_Shorts',
                        element: <PageReportModerationShorts />,
                        name: <FormattedMessage id="m.rm.shorts" />,
                        path: 'shorts',
                    },
                    {
                        access: 'Reports_Report-Moderation_Shorts-Comments',
                        element: <PageReportModerationShortsComments />,
                        name: <FormattedMessage id="m.rm.shorts-comments" />,
                        path: 'shorts-comments',
                    },
                    {
                        access: 'Reports_Report-Moderation_Profiles',
                        element: <PageReportReviewProfile />,
                        name: <FormattedMessage id="m.rm.profiles" />,
                        path: 'profiles',
                    },
                ],
                name: <FormattedMessage id="m.report-moderation" />,
                access: 'Reports_Report-Moderation',
                path: 'report-moderation',
            },
            {
                children: [
                    {
                        access: 'Reports_Moderation-Records_Posts',
                        element: <PageReportReviewRecordsPosts />,
                        name: <FormattedMessage id="m.rm.posts" />,
                        path: 'posts',
                    },
                    {
                        access: 'Reports_Moderation-Records_Posts-Comments',
                        element: <PageReportReviewRecordsReplies />,
                        name: <FormattedMessage id="m.rm.posts-comments" />,
                        path: 'posts-comments',
                    },
                    {
                        access: 'Reports_Moderation-Records_Shorts',
                        element: <PageReportModerationRecordsShorts />,
                        name: <FormattedMessage id="m.rm.shorts" />,
                        path: 'shorts',
                    },
                    {
                        access: 'Reports_Moderation-Records_Shorts-Comments',
                        element: <PageReportReviewRecordsShortsComments />,
                        name: <FormattedMessage id="m.rm.shorts-comments" />,
                        path: 'shorts-comments',
                    },
                    {
                        access: 'Reports_Moderation-Records_Profiles',
                        element: <PageReportReviewRecordsProfile />,
                        name: <FormattedMessage id="m.rm.profiles" />,
                        path: 'profiles',
                    },
                ],
                name: <FormattedMessage id="m.moderation-records" />,
                access: 'Reports_Moderation-Records',
                path: 'moderation-records',
            },
        ],
        icon: <AreaChartOutlined />,
        name: <FormattedMessage id="m.reports" />,
        access: 'Reports',
        path: 'reports',
    },
    /* 系统管理 System Management */
    {
        children: [
            {
                access: 'System-Management_Accounts',
                element: <PageAccountManagement />,
                name: <FormattedMessage id="m.System-Management_Accounts" />,
                path: 'accounts',
            },
            {
                access: 'System-Management_Roles',
                element: <PageRoleManagement />,
                name: <FormattedMessage id="m.System-Management_Roles" />,
                path: 'roles',
            },
            {
                access: 'System-Management_Logs',
                element: <PageLogs />,
                name: <FormattedMessage id="m.System-Management_Logs" />,
                path: 'logs',
            },
            {
                access: 'System-Management_Downloads',
                element: <PageExports />,
                name: <FormattedMessage id="m.System-Management_Exports" />,
                path: 'exports',
            },
        ],
        icon: <SettingOutlined />,
        name: <FormattedMessage id="m.System-Management" />,
        access: 'System-Management',
        path: 'system-management',
    },
] as MenuDataItem[];

export default routes;
