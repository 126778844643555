/**
 * Manual Moderation Posts
 *
 * 人工审核帖子
 */
import { useEffect, useState } from 'react';
import {
    gModerationLabels,
    humanRecordsRemainCountApi,
    ModerationLabelsType,
    humanRecordsLiveTodoApi,
    humanRecordsLiveUpdateApi,
} from '@/services/reviews';
import { useRequest } from 'ahooks';
import { ProCard } from '@ant-design/pro-components';
import { Button, Empty, Flex, Form, message } from 'antd';
import ModerationLiveCard from '../components/moderation-live-card';
import { useReviewLabelsStore } from '@/store/review-labels';

/**
 * APIs
 */

/**
 * Stores
 */

/**
 * Types
 */
import type { FormProps } from 'antd';
import type { HumanRecordsRecordResult, HumanRecordsUpdateReview, PostContent } from '@/services/reviews';
import { get } from 'lodash';

interface RecordForm extends HumanRecordsRecordResult<PostContent> {
    status?: 'approved' | 'rejected';
    remark?: string;
    index?: number;
}

type FormData = {
    records: RecordForm[];
};

/**
 * Constants
 */

/**
 * State
 */

const PageAutomatedModerationLive: React.FC = () => {
    /**
     * Hooks
     */
    // const { message } = App.useApp();
    const [form] = Form.useForm<FormData>();

    /**
     * States
     */

    const { setLabels } = useReviewLabelsStore();
    const [remainCount, setRemainCount] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);

    /**
     * Paginations
     */
    /**
     * Payloads
     */

    /**
     * Requests
     */
    const {
        loading: loadingHumanRecordsTodoApi,
        refresh: refreshHumanRecordsTodoApi,
        run: runHumanRecordsTodoApi,
    } = useRequest(humanRecordsLiveTodoApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            setCurrentCount(data?.list?.length || 0);
            form.setFieldsValue({
                records: data.list.map((item, index) => ({ ...item, index: index + 1 })),
            });
        },
    });

    const { run: runHumanRecordsRemainCountApi, refresh: refreshHumanRecordsRemainCountApi } = useRequest(
        humanRecordsRemainCountApi,
        {
            manual: true,
            onSuccess: ({ data: { code, data, msg } }) => {
                if (code !== 0) {
                    return message.error(msg);
                }
                setRemainCount(data);
            },
        },
    );

    const { run: runHumanRecordsUpdateApi, loading: loadingHumanRecordsUpdateApi } = useRequest(
        humanRecordsLiveUpdateApi,
        {
            manual: true,
            onSuccess: ({ data: { code, msg } }) => {
                if (code !== 0) {
                    return message.error(msg);
                }
                refreshHumanRecordsTodoApi();
                refreshHumanRecordsRemainCountApi();
            },
        },
    );

    /**
     * Requests
     */
    useRequest(() => gModerationLabels({ type: ModerationLabelsType.text, language: 'zh' }), {
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setLabels(ModerationLabelsType.text, data);
        },
    });

    useRequest(() => gModerationLabels({ type: ModerationLabelsType.image, language: 'zh' }), {
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setLabels(ModerationLabelsType.image, data);
        },
    });

    useRequest(() => gModerationLabels({ type: ModerationLabelsType.audio, language: 'zh' }), {
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setLabels(ModerationLabelsType.audio, data);
        },
    });

    useRequest(() => gModerationLabels({ type: ModerationLabelsType.video, language: 'zh' }), {
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setLabels(ModerationLabelsType.video, data);
        },
    });

    const scrollToCard = (id: string) => {
        const cardElement = document.getElementById(`moderation-card-${id}`);
        const offsetTop = cardElement?.offsetTop || 0;
        if (offsetTop) {
            document.documentElement.scrollTo({ top: offsetTop - 120, behavior: 'smooth' });
        }
    };

    /**
     * ChildrenProps
     */
    const formProps: FormProps<FormData> = {
        form,
        validateTrigger: 'onBlur',
        autoComplete: 'off',
        onFinishFailed: (errorInfo) => {
            const index = get(errorInfo, 'errorFields[0].name[1]', null);
            const records = get(errorInfo, 'values.records', null);
            const id = get(records, `[${index}].id`, null);
            if (!index || !id) {
                return;
            }
            scrollToCard(id);
        },
        onFinish: async (formData: any) => {
            const records = formData.records;
            const reviews: HumanRecordsUpdateReview[] = [];

            for (let i = 0; i < records.length; i++) {
                const record = records[i];
                if (!record.status || (record.status === 'rejected' && !record.violation_type)) {
                    scrollToCard(record.id);
                    return;
                }

                reviews.push({
                    id: record.id,
                    status: record.status,
                    violation_type: record.status !== 'approved' ? record.violation_type : undefined,
                    reason: record.status !== 'approved' ? record.reason : undefined,
                });
            }
            runHumanRecordsUpdateApi({ reviews });
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        runHumanRecordsTodoApi();
        runHumanRecordsRemainCountApi('live');
        const interval = setInterval(() => {
            refreshHumanRecordsRemainCountApi();
        }, 20000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Form {...formProps}>
            <Flex gap={8} vertical>
                <Flex style={{ position: 'sticky', top: '80px', zIndex: 1 }} vertical>
                    <ProCard bordered>
                        <Flex align="center" justify="space-between">
                            <span>待审量：{remainCount ? remainCount - (currentCount || 0) : 0}</span>
                            <Button
                                htmlType="submit"
                                type="primary"
                                loading={loadingHumanRecordsTodoApi || loadingHumanRecordsUpdateApi}
                            >
                                提交
                            </Button>
                        </Flex>
                    </ProCard>
                </Flex>

                <Flex style={{ flex: 1, overflowY: 'auto' }} gap={8} vertical>
                    <Form.List name="records">
                        {(fields) => (
                            <>
                                {(!Array.isArray(fields) || fields.length === 0) && (
                                    <div
                                        style={{
                                            height: '800px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Empty />
                                    </div>
                                )}
                                {fields.length > 0 &&
                                    fields.map((formField) => (
                                        <ModerationLiveCard
                                            form={form as any}
                                            key={formField.key}
                                            formField={formField}
                                        />
                                    ))}
                            </>
                        )}
                    </Form.List>
                </Flex>
            </Flex>
        </Form>
    );
};

export default PageAutomatedModerationLive;
