import { FormattedMessage } from 'react-intl';

export const LiveTypeEnum: Record<string, React.ReactNode> = {
    2: <FormattedMessage id="cm.t.s.audio" />,
    1: <FormattedMessage id="cm.t.s.video" />,
};

export const LiveTypeOptions = Object.entries(LiveTypeEnum).map(([value, label]) => {
    return {
        label,
        value,
    };
});
