export default {
    cm: {
        t: {
            c: {
                postUid: '帖子ID',
                userId: '发布者UID',
                shortsUid: '短视频ID',
                content: '正文',
                debate: '辩题',
                title: '标题',
                debateUsers: '正方&反方',
                forText: '正方',
                againstText: '反方',
                postVideo: '视频',
                postImg: '图片/视频',
                postAudio: '音频',
                textTag: '文本标签',
                imgTag: '图片标签',
                audioTag: '音频标签',
                videoTag: '视频标签',
                postTime: '发布时间',
                statusMachine: '检测结果',
                actionMethod: '审核流程',
                result: '审核结果',
                violationType: '违规类型',
                statusExecution: '机审结果',
                type: '场景',
                normal: '正常',
                suspected: '嫌疑',
                rejected: '违规',
                failure: '失败',
                name: 'Name',
                ticker: 'Ticker',
                description: '描述',
                group: 'Group',
                x: 'X',
                telegram: 'Telegram',
                website: 'Website',
                image: 'Image',
                createdAt: '创建时间',
                reason: '备注',
                roomId: '房间ID',
                creatorUid: '创建者UID',
                topic: '话题',
                coverImg: '封面',
            },
            s: {
                debate: '辩论',
                text: '文本',
                image: '图片',
                audio: '音频',
                video: '视频',
            },
        },
        status: {
            approved: '正常',
            rejected: '违规',
            failure: '失败',
            pending: '进行中',
            suspected: '嫌疑',
        },
        violationType: {
            politicsReligion: '政治&宗教',
            pornography: '色情',
            terrorismViolence: '暴恐',
            violenceGore: '暴力&血腥',
            minorProtection: '未成年人保护',
            controversialSpeech: '争议性言论',
            falseFraud: '虚假欺诈',
            illegalControversialContent: '非法&争议内容',
            privacyProtection: '隐私保护',
            trafficPromotion: '引流&宣传',
        },
        executionResult: {
            approved: '通过',
            rejected: '拒绝',
            manual: '人工',
        },
        type: {
            post: '帖子',
            comment: '评论',
            message: '私信',
            shorts: '短视频',
            user: '用户',
            postComment: '帖子评论',
            shortsComment: '短视频评论',
        },
        reviewsResult: {
            approved: '通过',
            rejected: '拒绝',
            manual: '人工',
        },
        actionMethod: {
            machine: '机审',
            manual: '机审+人工',
        },
    },
};
