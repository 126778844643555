/**
 * Live Moderation Card
 */
import { get } from 'lodash';
import { Button, Card, Col, Flex, Form, Input, Row, Select, Tag, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
// import ModerationCardMedia from './moderation-card-media';

/**
 * Types
 */
import type { FormInstance, FormListFieldData } from 'antd';
import { HumanRecordsRecordResult, LiveContent, ModerationLabelsType } from '@/services/reviews';
// import type { ModerationCardMediaType } from './moderation-card-media';
import { useReviewLabelsStore } from '@/store/review-labels';
import HighlightText from '@/components/height-line-text';
import { LiveTypeEnum } from './live-type-enum';
interface RecordForm extends HumanRecordsRecordResult<LiveContent> {
    status?: 'approved' | 'rejected';
    remark?: string;
    index?: number;
}

type FormData = {
    records: RecordForm[];
};

type ModerationCardProps = {
    form: FormInstance<FormData>;
    formField: FormListFieldData;
};

const ModerationCard: React.FC<ModerationCardProps> = ({ formField, form }) => {
    /**
     * States
     */
    const { formatMessage } = useIntl();
    const { labels: allLabels } = useReviewLabelsStore();
    const intl = useIntl();

    /**
     * Constants
     */
    const { name, ...restField } = formField;
    const record: RecordForm = form.getFieldValue('records')[name];
    const { result_machine, status, index } = record;

    const formatLabel = (
        type: ModerationLabelsType,
        label: number | undefined,
        secondLabel?: number,
        thirdLabel?: number,
    ) => {
        if (!label) return [];
        const formatLabels = [];
        const labels = allLabels?.[type];
        if (!labels) return [];
        const labelItem = labels.find((item) => Number(item.label) === Number(label));

        if (!labelItem) return [];
        formatLabels.push(labelItem.name);
        if (secondLabel) {
            const secondLabelItem = labelItem?.subLabels?.find((item) => Number(item.code) === Number(secondLabel));
            if (secondLabelItem) formatLabels.push(secondLabelItem.name);

            if (thirdLabel) {
                const thirdLabelItem = secondLabelItem?.subLabels?.find(
                    (item) => Number(item.code) === Number(thirdLabel),
                );
                if (thirdLabelItem) formatLabels.push(thirdLabelItem.name);
            }
        }

        return [formatLabels.join('-')];
    };

    // const liveCoverImgUrl = get(record, 'content.image_url', '');

    const textMachine = Array.isArray(result_machine) && result_machine.find((item) => item.type === 'text');

    const keywords: string[] = get(textMachine, 'response.result.antispam.mergeHints', []);

    const currentKeywords: string[] = [];
    keywords.map((item) => currentKeywords.push(...item.split(', ')));

    console.log(currentKeywords);

    return (
        <Card id={`moderation-card-${record.id}`}>
            <Row style={{ position: 'relative', minHeight: 250 }} gutter={[16, 16]}>
                <div style={{ position: 'absolute', top: 0, left: 0 }}>{index}</div>
                <Col
                    flex="220px"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRight: '1px solid #f0f0f0',
                    }}
                >
                    <div>UID：{record.user_id}</div>
                    <div>类型：{LiveTypeEnum[record.content.type]}</div>
                    <div>房间ID：{record.biz_id}</div>
                </Col>
                <Col flex="1" style={{ borderRight: '1px solid #f0f0f0' }}>
                    <Row style={{ height: '100%' }} gutter={[8, 8]}>
                        <Col xs={24} sm={24} xl={12}>
                            <Flex style={{ height: '100%' }} gap={8} vertical>
                                <Flex flex={1} gap={8} vertical>
                                    <div style={{ backgroundColor: '#f2f2f2', minHeight: '22px' }}>
                                        <Typography.Title level={5}>
                                            <HighlightText
                                                text={record?.content?.title || ''}
                                                keywords={currentKeywords}
                                            />
                                        </Typography.Title>
                                    </div>
                                    <div style={{ backgroundColor: '#f2f2f2', minHeight: '22px' }}>
                                        {(record?.content?.for_text || record?.content?.against_text) && (
                                            <>
                                                <div>
                                                    正方:
                                                    <HighlightText
                                                        text={record?.content?.for_text || ''}
                                                        keywords={currentKeywords}
                                                    />
                                                </div>
                                                <div>
                                                    反方:
                                                    <HighlightText
                                                        text={record?.content?.against_text || ''}
                                                        keywords={currentKeywords}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Flex>
                                <Flex gap={4} wrap="wrap">
                                    {Array.isArray(result_machine) &&
                                        result_machine.map((item) => {
                                            if (['text'].includes(item.type)) {
                                                const { result } = item?.response || {};
                                                const { antispam } = result || {};
                                                const [label, secondLabel, thirdLabel] = [
                                                    antispam?.label,
                                                    antispam?.secondLabel,
                                                    antispam?.thirdLabel,
                                                ];

                                                const tags = formatLabel(
                                                    ModerationLabelsType[`${item.type}`],
                                                    label,
                                                    secondLabel,
                                                    thirdLabel,
                                                );
                                                return tags.map((tag) => <Tag color="warning">{tag}</Tag>);
                                            }
                                            return undefined;
                                        })}
                                </Flex>
                            </Flex>
                        </Col>
                        {/* {Array.isArray(result_machine) && (
                            <Col xs={24} sm={24} xl={12}>
                                <Flex gap={8} wrap="wrap">
                                    {result_machine.map((item) => {
                                        const { result } = item?.response || {};
                                        const { antispam } = result || {};
                                        const dataId = antispam?.dataId || result?.dataId || '';

                                        const [label, secondLabel, thirdLabel] = [
                                            antispam?.label,
                                            antispam?.secondLabel,
                                            antispam?.thirdLabel,
                                        ];

                                        return (
                                            <>
                                                <ModerationCardMedia
                                                    key={dataId}
                                                    type={item.type as ModerationCardMediaType}
                                                    isViolation={item.status !== 'approved'}
                                                    status={item.status}
                                                    tags={formatLabel(
                                                        ModerationLabelsType[`${item.type}`],
                                                        label,
                                                        secondLabel,
                                                        thirdLabel,
                                                    )}
                                                    src={liveCoverImgUrl}
                                                />
                                            </>
                                        );
                                    })}
                                </Flex>
                            </Col>
                        )} */}
                    </Row>
                </Col>
                <Col flex="none" style={{ display: 'flex', width: '200px', alignItems: 'center', gap: 8 }}>
                    <Flex vertical gap={8}>
                        <Form.Item {...restField} name={[name, 'status']}>
                            <Flex style={{ width: '200px' }} gap={8} justify="center">
                                <Button
                                    onClick={() => {
                                        const records = form.getFieldValue('records');
                                        records[name] = {
                                            ...record,
                                            status: 'approved',
                                        };
                                        form.setFieldValue('records', [...records]);
                                    }}
                                    type={status === 'approved' ? 'primary' : 'default'}
                                >
                                    正常
                                </Button>
                                <Button
                                    onClick={() => {
                                        const records = form.getFieldValue('records');
                                        records[name] = {
                                            ...record,
                                            status: 'rejected',
                                        };
                                        form.setFieldValue('records', [...records]);
                                    }}
                                    danger={status === 'rejected'}
                                    type={status === 'rejected' ? 'primary' : 'default'}
                                >
                                    违规
                                </Button>
                            </Flex>
                        </Form.Item>
                        {status === 'rejected' && (
                            <Flex vertical>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'violation_type']}
                                    rules={[{ required: true, message: formatMessage({ id: 'common.notEmpty' }) }]}
                                >
                                    <Select
                                        allowClear={true}
                                        showSearch={true}
                                        optionFilterProp="label"
                                        options={[
                                            {
                                                label: intl.formatMessage({ id: 'cm.violationType.politicsReligion' }),
                                                value: 'Politics&Religion',
                                            },
                                            {
                                                label: intl.formatMessage({ id: 'cm.violationType.pornography' }),
                                                value: 'Pornography',
                                            },
                                            {
                                                label: intl.formatMessage({ id: 'cm.violationType.terrorismViolence' }),
                                                value: 'Terrorism&Violence',
                                            },
                                            {
                                                label: intl.formatMessage({ id: 'cm.violationType.violenceGore' }),
                                                value: 'Violence&Gore',
                                            },
                                            {
                                                label: intl.formatMessage({ id: 'cm.violationType.minorProtection' }),
                                                value: 'MinorProtection',
                                            },
                                            {
                                                label: intl.formatMessage({
                                                    id: 'cm.violationType.controversialSpeech',
                                                }),
                                                value: 'ControversialSpeech',
                                            },
                                            {
                                                label: intl.formatMessage({ id: 'cm.violationType.falseFraud' }),
                                                value: 'FalseFraud',
                                            },
                                            {
                                                label: intl.formatMessage({
                                                    id: 'cm.violationType.illegalControversialContent',
                                                }),
                                                value: 'Illegal&ControversialContent',
                                            },
                                            {
                                                label: intl.formatMessage({ id: 'cm.violationType.privacyProtection' }),
                                                value: 'PrivacyProtection',
                                            },
                                            {
                                                label: intl.formatMessage({ id: 'cm.violationType.trafficPromotion' }),
                                                value: 'Traffic&Promotion',
                                            },
                                        ]}
                                        placeholder={<FormattedMessage id="cm.t.c.violationType" />}
                                        style={{ width: 200 }}
                                    />
                                </Form.Item>
                                <Form.Item {...restField} name={[name, 'reason']}>
                                    <Input.TextArea maxLength={100} rows={3} showCount placeholder="备注" />
                                </Form.Item>
                            </Flex>
                        )}
                    </Flex>
                </Col>
            </Row>
        </Card>
    );
};

export default ModerationCard;
